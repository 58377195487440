import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Category.css';
import Url from '../../config/Url';

function Category() {
    const [category, setCategory] = useState([])
    const navigate = useNavigate();
    const token = sessionStorage.getItem('loginToken');

    useEffect(() => {
        fetch(`${Url.BaseUrl}/list-category`)
            .then((response) => response.json())
            .then((data) => {
                const sortedData = [...data.data].sort((a, b) =>
                    a.category_name > b.category_name ? 1 : -1
                )
                // console.log('ascending category', sortedData);
                setCategory(sortedData);
                // console.log(data);
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    const ProductList = (id) => {
        if (token === null) {
            navigate('/login')
        } else {
            navigate(`/listing/${id}`)
        }
    }

    // const strAsc = [...category].sort((a, b) =>
    //     a.category_name > b.category_name ? 1 : -1,
    // )
    // console.log('ascending category', strAsc);


    return (
        <div className='categories px-md-5 px-3 py-md-5 py-3'>
            {category.map((cat) => {
                return (
                    <div key={cat.id} className='category-box card' onClick={() => ProductList(`${cat.id}`)}>
                        <img src={cat.category_icon} alt='' className='category-icon img-fluid' />
                        <p className='text-capitalize card-text'>{cat.category_name}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default Category;