import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Url from '../../config/Url';

const Login = () => {
  const [message, setMessage] = useState('');
  const [data, setData] = useState({ phone_number: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({ ...data, [name]: value })
    // console.log(data);
  }

  let alertMessage;
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(`${Url.BaseUrl}/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then((json) => {
        // console.log(json)
        if (json.success === true) {
          sessionStorage.setItem('loginToken', json.token);
          alertMessage = json.message;
          navigate('/');
          setMessage(alertMessage)
          // console.log('login token', json.token);
        } else {
          // console.log('error in.....')
          alertMessage = json.message;
          setMessage(alertMessage);
        }
      })
      .catch((err)=>{
        console.error(err);
      })
  }
  return (
    <div className='login-container my-5'>
      <p className='text-danger text-center'>{message}</p>
      <form className='d-flex flex-column' onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="phone_number">Phone Number</label>
          <input type="text" name="phone_number" value={data.phone_number} onChange={handleChange} className="form-control" placeholder="Enter your registered phone number" required />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" name="password" value={data.password} onChange={handleChange} className="form-control" placeholder="Enter your password" required />
        </div>
        <div className='d-sm-flex justify-content-between mb-2'>
          <p>Forgot Password? <Link to='/send-otp'>Reset Password</Link></p>
          <p>Dont't have account? <Link to='/register'>Register</Link></p>
        </div>
        <button type='submit' className='text-uppercase btn btn-primary'>login</button>
      </form>
    </div>
  )
}

export default Login;