import React from 'react';
import { useNavigate } from 'react-router-dom';
import Url from '../../config/Url';

const ResetPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget)
    const userPhone = sessionStorage.getItem('userPhone')
    formData.append('phone_number', userPhone)
    // console.log(formData)
    e.currentTarget.reset();

    await fetch(`${Url.BaseUrl}/reset-password`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `${sessionStorage.getItem('otpToken')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          // console.log('data...', data)
          alert(data.message)
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        } else {
          alert(data.message)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
  return (
    <div className='reset-password-container my-5'>
      <form className='d-flex flex-column' encType='multipart/form-data' onSubmit={handleSubmit}>
        {/* <div className="form-group">
          <label htmlFor="phone_number">Phone Number</label>
          <input type="text" name="phone_number" className="form-control" placeholder="Enter your registered phone number" required />
        </div> */}
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" name="password" minLength={8} className="form-control" placeholder="Enter your new password" required />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password">Confirm Password</label>
          <input type="password" name="confirm_password" className="form-control" placeholder="Re-enter your password" />
        </div>
        <button type='submit' className='text-uppercase btn btn-primary'>change password</button>
      </form>
    </div>
  )
}

export default ResetPassword;