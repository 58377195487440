import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../static/images/dialforguide-logo.png';
import './Nav.css';

function NavMenu() {
    const navigate = useNavigate();
    const loginToken = sessionStorage.getItem('loginToken');

    const ClearSessionStorage = () => {
        sessionStorage.clear();
        navigate('/');
        // console.log('session clear', sessionStorage.clear());
    }

    const LoginChecker = () => {
        if (loginToken === null) {
            navigate('/login')
        } else {
            navigate('/create-listing')
        }
    }

    const ButtonValidator = () => {
        if (loginToken === null) {
            return (<Link to='/login' className='btn btn-primary'>Login / Sign Up</Link>)
        } else {
            return (<span onClick={ClearSessionStorage} className='btn btn-primary'>Logout</span>)
        }
    }
    return (
        <Navbar expand='md' className="bg-body-tertiary" sticky="top">
            <Container>
                <Link to='/' className='brand'>
                    <img src={Logo} alt="" />
                </Link>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <span className='me-md-3 blink-class'>
                        <a href='https://play.google.com/store/apps/details?id=com.nivabusinesssolution.dfgmobileapp&hl=en_US' className='text-capitalize'>download app</a>
                    </span>
                    <Link to='/' className='nav-link mb-2 mb-md-0'>Home</Link>
                    <span onClick={() => LoginChecker()} className='d-flex flex-column nav-link mb-2 mb-md-0'>
                        Free Listing
                        <small className='text-uppercase bg-danger text-light ps-2 text-type'>business</small>
                    </span>
                    {ButtonValidator()}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavMenu;