import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Url from '../../config/Url';

import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import CardGroup from 'react-bootstrap/CardGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Image from '../../static/images/banner-3.png';
import './Listing.css';

function ListingDetail() {
    const [listings, setlistings] = useState([]);
    const [data, setData] = useState({
        name: '',
        phone_number: '',
        email: ''
    });
    const [showModal, setShowModal] = useState(false);
    const params = useParams();
    const listId = Number(params.listingId);
    // console.log("shown params of list", params);

    useEffect(() => {
        fetch(`${Url.BaseUrl}/list-listing`, {
            headers: {
                "Authorization": `${sessionStorage.getItem('loginToken')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data.data.id)
                const result = data.data.filter((curData) => curData.id === listId)
                setlistings(result);
                // console.log(result);
                // setlistings(data.data)
            })
            .catch((err) => {
                console.error(err);
            })
    }, [])

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    let businessName,
        businessImage,
        businessAdd,
        businessPhone,
        businessEmail,
        businessWeb,
        businessServices,
        businessDesc

    {
        listings.map((listDetail) => {
            businessName = listDetail.business_name
            businessImage = listDetail.business_image
            businessAdd = listDetail.business_address
            businessPhone = listDetail.business_phone
            businessEmail = listDetail.business_email
            businessWeb = listDetail.business_url
            businessServices = listDetail.business_services
            businessDesc = listDetail.business_desc
        })
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData({ ...data, [name]: value })
        // console.log('inquiry data', data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data);
        const response = await fetch(`${Url.BaseUrl}/post-inquiry`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    alert(data.message);
                    setData({
                        name: '',
                        phone_number: '',
                        email: ''
                    })
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return (
        <div>
            <>
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-light'>Send us your query</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='inquiry-form' onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" value={data.name} onChange={handleChange} className="form-control" placeholder="Your name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Mobile Number</label>
                                <input type="text" name="phone_number" value={data.phone_number} onChange={handleChange} className="form-control" placeholder="Your mobile number" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" value={data.email} onChange={handleChange} className="form-control" placeholder="Your email" />
                            </div>
                            <button type='submit' className='text-uppercase btn btn-primary'>send inquiry</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </>

            <div className="detail-image-container mb-3">
                <img src={businessImage} alt="" />
            </div>
            <CardGroup className='card-container'>
                <Card className='px-3 pb-md-3 pb-1'>
                    <Card.Body className='px-lg-5 py-md-0 mt-md-0 px-0'>
                        <Card.Text className='fs-3 fw-bold link mb-2'>{businessName}</Card.Text>
                        <Card.Text>
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> {businessAdd}
                        </Card.Text>
                        <Card.Text>
                            <Link to={`tel:${businessPhone}`} className='btn btn-success me-3 card-btn'>
                                <FontAwesomeIcon icon={faPhone} /> Call
                            </Link>
                            <Link onClick={handleShow} className='btn btn-primary card-btn text-capitalize'>send inquiry</Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
            <CardGroup className='card-container mt-md-3'>
                <Card className='px-3 py-md-3 pb-1'>
                    <Card.Body className='px-lg-5 py-md-0 mt-md-0 px-0'>
                        <div className='mb-3 d-flex flex-column flex-md-row justify-content-between'>
                            <Link className='link mb-3' to={`mailto:${businessEmail}`}>
                                <FontAwesomeIcon icon={faEnvelope} /> {businessEmail}
                            </Link>
                            <Link className='link' to={businessWeb} target='_blank'>
                                <FontAwesomeIcon icon={faGlobe} /> {businessWeb}
                            </Link>
                        </div>
                        <div className='d-md-flex flex-row justify-content-between'>
                            <Card.Text className='mb-3'>
                                <Card.Title className='text-capitalize mb-4'>quick information</Card.Title>
                                <Card.Text>{businessServices}</Card.Text>
                            </Card.Text>
                            <Card.Text>
                                <Card.Title className='text-capitalize mb-4'>Timings</Card.Title>
                                <Card.Text>{businessDesc}</Card.Text>
                            </Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    )
}

export default ListingDetail;