import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import NavMenu from './layout/Header/NavMenu';
import Listing from './pages/Listing/Listing';
import ListingDetail from './pages/Listing/ListingDetail';
import CreateListing from './pages/Listing/CreateListing';
import Register from './pages/Account/Register';
import VerifyOtp from './pages/Account/VerifyOtp';
import Login from './pages/Account/Login';
import SendOtp from './pages/Account/SendOtp';
import Footer from './layout/Footer/Footer';
import NotFound from './pages/NotFound/NotFound';
import ResetPassword from './pages/Account/ResetPassword';
import TermsAndCondition from './pages/TermsAndCondition.jsx/TermsAndCondition';
import VerifyOtpForPassReset from './pages/Account/VerifyOtpForPassReset';
import Help from './pages/Help/Help';

function App() {
  return (
    <>
      <NavMenu />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/send-otp' element={<SendOtp />} />
        <Route path='/verify-otp' element={<VerifyOtp />} />
        <Route path='/verify-otp-for-pass-reset' element={<VerifyOtpForPassReset />} />
        <Route path='/create-listing' element={<CreateListing />} />
        <Route path='/listing/:categoryId' element={<Listing />}></Route>
        <Route path='/business-details/:listingId' element={<ListingDetail />} />
        <Route path='/terms-condition' element={<TermsAndCondition />} />
        <Route path='/help' element={<Help />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
