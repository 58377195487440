import React from 'react';
import {Link} from 'react-router-dom';
import './NotFound.css';
import ErrorImage from '../../static/images/error.png';

const NotFound = () => {
  return (
    <div className='error d-flex flex-column flex-md-row justify-content-center align-items-center'>
      <div className="img-container me-lg-5">
        <img src={ErrorImage} alt="" />
      </div>
      <div className='error-content text-center ms-lg-5'>
        <h4 className='error-text text-capitalize bg-danger text-light py-2 px-3'>the page you are looking for not found</h4>
        <Link to="/" className='btn btn-info mt-2'>Back to Home</Link>
      </div>
    </div>
  )
}

export default NotFound;