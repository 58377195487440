import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container mt-3'>
            <div className='main-footer-container py-3'>
                <div className='footer-contact'>
                    <p className='fs-5 text-light mb-1'>Think About Us</p>
                    <span><FontAwesomeIcon icon={faHome} className='me-2' /> Birgunj, Parsa, Nepal</span>
                    <a href="tel:+977-9820995933"><FontAwesomeIcon icon={faPhone} className='me-2' /> +977-9820995933</a>
                    <a href='mailto:info@dialforguide.com'><FontAwesomeIcon icon={faEnvelope} className='me-2' /> info@dialforguide.com</a>
                </div>
                <div className='footer-icon'>
                    <p className='fs-5 text-light mb-1'>Quick Lick</p>
                    <div className='d-flex flex-column'>
                        <Link to='/help' className="text-light text-decoration-none">Help</Link>
                        <a href='https://play.google.com/store/apps/details?id=com.nivabusinesssolution.dfgmobileapp&hl=en_US' className='text-capitalize text-decoration-none text-light'>download app</a>
                    </div>
                </div>
                <div className='footer-icon'>
                    <p className='fs-5 text-light mb-1'>Follow Us</p>
                    <a href='https://www.facebook.com/dialforguide' target='_blank' className='social-icon'><FontAwesomeIcon icon={faFacebook} /></a>
                    <a href='https://www.instagram.com/dialforguide' target='_blank' className='social-icon'><FontAwesomeIcon icon={faInstagram} /></a>
                </div>
            </div>
            <div className='bg-dark py-2 text-light text-center'>
                © 2023 Copyright:
                <a href='www.dialforguide.com' className='ms-2 text-decoration-none text-light'>Dial For Guide</a>
            </div>
        </div>
    )
}

export default Footer;