import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Url from '../../config/Url';
import Modal from 'react-bootstrap/Modal';

const VerifyOtp = () => {
    const [data, setData] = useState({ phone_number: '', otp: '' });
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData({
            ...data,
            [name]: value,
            phone_number: `${sessionStorage.getItem('userPhone')}`
        })
    }

    let alertMessage;
    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${Url.BaseUrl}/verify-otp`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${sessionStorage.getItem('otpToken')}`
            }
        })
            .then(response => response.json())
            .then((json) => {
                // console.log('verify data', json);
                if (json.success === true) {
                    alertMessage = json.message;
                    setMessage(alertMessage);
                    handleShow();
                    setData({
                        phone_number: '',
                        otp: ''
                    })
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000)
                } else {
                    alertMessage = json.message;
                    setMessage(alertMessage);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div className='otp-container my-5'>
            <>
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-light'>Otp Verified</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='text-danger text-center'>{message}</p>
                    </Modal.Body>
                </Modal>
            </>
            <h3 className='text-center text-capitalize'>verify your otp</h3>
            <p className='text-danger text-center'>{message}</p>
            <form className='d-flex flex-column' onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="first-name">Your Otp</label>
                    <input type="text" name="otp" value={data.otp} onChange={handleChange} className="form-control" />
                </div>
                <button type='submit' className='text-uppercase btn btn-primary'>verify</button>
            </form>
        </div>
    )
}

export default VerifyOtp;