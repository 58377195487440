import React from 'react';
import Slider from '../pages/Slider/Slider';
import Category from '../pages/Category/Category';

function Home() {
  return (
    <React.Fragment>
      <Slider />
      <Category />
    </React.Fragment>
  )
}

export default Home;