import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Url from '../../config/Url';
import './Slider.css';
import BannerImg from '../../static/images/banner-3.png';

const Slider = () => {
    const [sliders, setSliders] = useState([])
    const [error, setError] = useState('')

    useEffect(() => {
        fetch(`${Url.BaseUrl}/list-slider`)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                setSliders(data.data)
            })
            .catch((err) => {
                console.error(err);
                setError(err.message);
            })
    }, [])

    return (
        <Carousel slide={false}>
            {sliders.map((slider) => {
                return (
                    <Carousel.Item key={slider.id}>
                        <img
                            className="d-block w-100"
                            src={slider.slider_image}
                            alt="slide"
                        />
                        {/* <Carousel.Caption>
                            <h5 className="mb-md-3 mb-2">{slider.slider_name}</h5>
                            <button className='btn btn-primary mb-md-3 mb-2'>{slider.slider_btn}</button>
                            <p>{slider.slider_desc}</p>
                        </Carousel.Caption> */}
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

export default Slider;