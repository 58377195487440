import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Url from '../../config/Url';
import './Account.css';

const Register = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        password: "",
        confirm_password: ""
    });
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData({ ...data, [name]: value });
    }
    let alertMessage;
    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${Url.BaseUrl}/signup`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then((json) => {
                // console.log(json);
                if (json.success === true) {
                    alertMessage = json.message;
                    alert(alertMessage);
                    setMessage(alertMessage);
                    sessionStorage.setItem('userPhone', json.phone_number);
                    sessionStorage.setItem('otpToken', json.otp_token)
                    setData({
                        first_name: "",
                        last_name: "",
                        phone_number: "",
                        email: "",
                        password: "",
                        confirm_password: ""
                    })
                    setTimeout(() => {
                        navigate('/verify-otp');
                    }, 3000)
                } else {
                    alertMessage = json.message;
                    alert(alertMessage)
                    setMessage(alertMessage);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return (
        <div className='register-container'>
            <h3 className='text-center text-capitalize'>Create account</h3>
            <form className='d-flex flex-column' onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="first-name">First Name</label>
                    <input type="text" name="first_name" value={data.first_name} onChange={handleChange} className="form-control" placeholder="Your first name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="last-name">Last Name</label>
                    <input type="text" name="last_name" value={data.last_name} onChange={handleChange} className="form-control" placeholder="Your last name" />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="number" name="phone_number" value={data.phone_number} minLength={10} maxLength={15} onChange={handleChange} className="form-control" placeholder="Your phone number" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" value={data.email} onChange={handleChange} className="form-control" placeholder="Your email" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" value={data.password} onChange={handleChange} minLength={8} className="form-control" placeholder="Enter your password" required />
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" name="confirm_password" value={data.confirm_password} onChange={handleChange} className="form-control" placeholder="Re-enter your password" />
                </div>
                <p>
                    creating your account means accepting
                    <Link to="/terms-condition" className='ms-2'>terms & condition</Link>
                </p>
                <button type='submit' className='text-uppercase btn btn-primary'>signup</button>
            </form>
        </div>
    )
}

export default Register;