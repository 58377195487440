import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Url from '../../config/Url';

function CreateListing() {
    const [category, setCategory] = useState([]);
    const navigate = useNavigate();

    let alertMessage;
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data);
        const formData = new FormData(e.currentTarget)
        // console.log(formData)

        // to erase form input
        e.currentTarget.reset();

        const response = await fetch(`${Url.BaseUrl}/create-listing`, {
            method: 'POST',
            body: formData,
            headers: {
                "Authorization": `${sessionStorage.getItem('loginToken')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    // console.log('response data', data);
                    alert(data.message)
                    setTimeout(() => {
                        navigate('/');
                    }, 3000)
                } else {
                    alert(data.message)
                }
            })
            .catch((err) => {
                // console.error('response error', err.message)
                console.error(err);
            })

        // const result = response.json();
        // console.log(result);
    }
    useEffect(() => {
        fetch(`${Url.BaseUrl}/list-category`)
            .then((response) => response.json())
            .then((catData) => {
                setCategory(catData.data);
                // console.log(data.data);
            })
            .catch((err) => {
                console.error(err);
            })
    }, [])
    return (
        <div className='listing-form-container my-5'>
            <h3 className='text-center text-capitalize'>Fill the form to list your business</h3>
            <form action="" id='create-form' encType='multipart/form-data' className='create-listing-form d-flex flex-column' onSubmit={handleSubmit}>

                <div className="form-group">
                    <label htmlFor="business-category">Select Category</label>
                    <select className="form-control" name="category_id" required>
                        <option value="">Select</option>
                        {category.map((cat) => <option key={cat.id} value={cat.id}>{cat.category_name}</option>)}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="business-name">Business Name</label>
                    <input type="text" name="business_name" className="form-control" placeholder="Business Name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="business-image">Logo/Image</label>
                    <input type="file" accept='image/*' name="business_image" className="form-control" placeholder="Banner or logo of your company" />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" name="business_address" className="form-control" placeholder="Full Address" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input type="text" name="business_phone" className="form-control" placeholder="Phone Number" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="business_email" className="form-control" placeholder="Valid email address" />
                </div>
                <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input type="text" name="business_url" className="form-control" placeholder="Company website" />
                </div>
                <div className="form-group">
                    <label htmlFor="services">Services</label>
                    <textarea name="business_services" className="form-control" placeholder="What service do you provide" cols="30" rows="3" />
                </div>
                <div className="form-group">
                    <label htmlFor="extra-info">Extra Info</label>
                    <textarea name="business_desc" className="form-control" placeholder="Additional information" cols="30" rows="3" />
                </div>
                <div className="form-group">
                    <label htmlFor="latitude">Latitude</label>
                    <input type="number" name="business_latitude" className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="longitude">Longitude</label>
                    <input type="number" name="business_longitude" className="form-control" />
                </div>
                {/* <p className='text-danger text-center'>{message}</p> */}
                <button type='submit' className='btn btn-primary'>Submit</button>
            </form>
        </div>
    )
}

export default CreateListing;