import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Url from '../../config/Url';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './Listing.css';
import CatImage from '../../static/images/category.png';


function Listing() {
    const [listings, setlistings] = useState([]);
    const [data, setData] = useState({
        name: '',
        phone_number: '',
        email: ''
    })
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const catId = Number(params.categoryId);

    useEffect(() => {
        fetch(`${Url.BaseUrl}/list-listing`, {
            headers: {
                "Authorization": `${sessionStorage.getItem('loginToken')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log('category data...', data.data)
                const result = data.data.filter((curData) => curData.category_id === catId)
                setlistings(result);
                // console.log(result)
            })
            .catch((err) => {
                console.error(err);
            })
    }, [catId])

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const BusinessDetail = (id) => {
        navigate(`/business-details/${id}`)
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData({ ...data, [name]: value })
        // console.log('inquiry data', data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data);
        const response = await fetch(`${Url.BaseUrl}/post-inquiry`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    alert(data.message)
                    setData({
                        name: '',
                        phone_number: '',
                        email: ''
                    })
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return (
        <div className='listing-container'>
            <>
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-light'>Send us your query</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='inquiry-form' onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" value={data.name} onChange={handleChange} className="form-control" placeholder="Your name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Mobile Number</label>
                                <input type="text" name="phone_number" value={data.phone_number} onChange={handleChange} className="form-control" placeholder="Your mobile number" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email (optional)</label>
                                <input type="email" name="email" value={data.email} onChange={handleChange} className="form-control" placeholder="Your email" />
                            </div>
                            <button type='submit' className='text-uppercase btn btn-primary'>send inquiry</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
            <div className="image-container mb-md-5 mb-3">
                <img src={CatImage} alt="" />
            </div>
            {listings.map((list) => {
                return (
                    <div>
                        <CardGroup className='card-container mb-md-3' key={list.id}>
                            <Card className='px-3 py-3'>
                                <Card.Img variant="top" src={list.business_image} className='business-image' />
                                <Card.Body className='px-md-5 py-md-0 mt-md-0 px-0'>
                                    <a className='fs-3 fw-bold link text-capitalize' onClick={() => BusinessDetail(list.id)}>{list.business_name}</a>
                                    <Card.Text>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {list.business_address}
                                    </Card.Text>
                                    <Card.Text>
                                        <Link to={`tel:${list.business_phone}`} className='btn btn-success me-3 card-btn'>
                                            <FontAwesomeIcon icon={faPhone} /> Call
                                        </Link>
                                        <Link onClick={handleShow} className='btn btn-primary card-btn text-capitalize'>send inquiry</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </div>
                )
            })}
        </div>
    )
}

export default Listing;