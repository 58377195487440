import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Url from '../../config/Url';

const SendOtp = () => {
    const [message, setMessage] = useState('');
    const [data, setData] = useState({ phone_number: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    }

    let alertMessage;
    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${Url.BaseUrl}/send-otp`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then((json) => {
                // console.log(json)
                if (json.success === true) {
                    sessionStorage.setItem('userPhone', json.phone_number);
                    sessionStorage.setItem('otpToken', json.otp_token)
                    alertMessage = json.message;
                    alert(alertMessage)
                    setMessage(alertMessage)
                    setTimeout(() => {
                        navigate('/verify-otp-for-pass-reset')
                    }, 3000)
                } else {
                    alertMessage = json.message;
                    alert(alertMessage)
                    setMessage(alertMessage);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return (
        <div className='otp-container my-5'>
            {/* <p className='text-danger text-center'>{message}</p> */}
            <form className='d-flex flex-column' onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="phone_number">Phone Number</label>
                    <input type="text" name="phone_number" value={data.phone_number} onChange={handleChange} className="form-control" placeholder="Enter your registered phone number" required />
                </div>
                <button type='submit' className='text-uppercase btn btn-primary'>send otp</button>
            </form>
        </div>
    )
}

export default SendOtp;