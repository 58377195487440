import React, { useState, useEffect } from 'react';
import Url from '../../config/Url';

const TermsAndCondition = () => {
    const [termsConditions, setTermsConditions] = useState([])

    useEffect(() => {
        fetch(`${Url.BaseUrl}/get-terms-conditions`)
            .then((response) => response.json())
            .then((data) => {
                setTermsConditions(data.data)
                // console.log(datas);
            })
            .catch((err) => {
                console.error(err);
            })
    }, [])
    return (
        <div>
            {termsConditions.map((terms) => {
                return (
                    <div key={terms.id} className='py-5 mx-md-5 mx-2' dangerouslySetInnerHTML={{ __html: terms.condition }}></div>
                )
            })}
        </div>
    )
}

export default TermsAndCondition;